import firebase from '../lib/firebase/client';
import { isBoolean, isNumber, isString, isTimestamp } from '../lib/guards';
import { FirebaseModel } from './FirebaseModel';
import { Category } from './Category';
import { Subcategory } from './Subcategory';
import { Description } from './Description';
import { Contact } from './Contact';
import { fromDateToTimestamp, fromStringToDate } from '../lib/dateHandler';

export class Request extends FirebaseModel {
  categories?: Array<Category | string>;

  subcategories?: Array<Subcategory | string>;

  descriptions?: Array<Description>;

  contact?: Contact;

  extraInfo?: {
    quantity?: number;
    location?: string;
    hourInit?: string;
    hourClose?: string;
    eventType?: string;
    date?: Date;
    dateLimit?: Date;
  };

  constructor();

  constructor(doc: any);

  constructor(doc?: any) {
    super(
      isString(doc?.id) ? doc?.id : undefined,
      isBoolean(doc?.active) ? doc?.active : true,
      isTimestamp(doc?.createdAt) ? doc?.createdAt?.toDate() : undefined,
      isTimestamp(doc?.updatedAt) ? doc?.updatedAt?.toDate() : undefined
    );
    if (typeof doc === 'undefined') {
      this.categories = [];
      this.subcategories = [];
      this.descriptions = [];
      this.contact = null;
      this.extraInfo = {
        quantity: null,
        location: null,
        hourInit: null,
        hourClose: null,
        eventType: null,
        date: null,
        dateLimit: null,
      };
    }
    this.id = isString(doc?.id) ? doc?.id : undefined;
    this.active = isBoolean(doc?.active) ? doc?.active : true;
    this.createdAt = isTimestamp(doc?.createdAt)
      ? doc?.createdAt?.toDate()
      : null;
    this.updatedAt = isTimestamp(doc?.updatedAt)
      ? doc?.updatedAt?.toDate()
      : null;
    this.categories = Array.isArray(doc?.categories)
      ? doc?.categories?.map((category) =>
          isString(category) ? null : Category.factory(category)
        )
      : [];
    this.subcategories = Array.isArray(doc?.subcategories)
      ? doc?.subcategories?.map((subcategory) =>
          isString(subcategory) ? null : Subcategory.factory(subcategory)
        )
      : [];
    this.descriptions = Array.isArray(doc?.descriptions)
      ? doc?.descriptions?.map((description) =>
          Description.factory(description)
        )
      : [];
    this.contact = Contact.factory(doc?.contact);
    this.extraInfo = {
      quantity: isNumber(doc?.quantity) ? Number(doc?.quantity) : null,
      location: isString(doc?.location) ? doc?.location ?? null : null,
      hourInit: isString(doc?.hourInit) ? doc?.hourInit ?? null : null,
      hourClose: isString(doc?.hourClose) ? doc?.hourClose ?? null : null,
      eventType: isString(doc?.eventType) ? doc?.eventType ?? null : null,
      date: isTimestamp(doc?.date) ? doc?.date?.toDate() : null,
      dateLimit: isTimestamp(doc?.dateLimit) ? doc?.dateLimit?.toDate() : null,
    };
  }

  static factory(doc: any) {
    if (typeof doc === 'undefined' || doc === null) return null;
    return new Request(doc);
  }

  static toFirebaseFormat(self: Partial<Request>) {
    return {
      categories:
        self?.subcategories?.map((category) =>
          isString(category) ? category ?? null : category?.id ?? null
        ) ?? null,
      subcategories:
        self?.subcategories?.map((subcategory) =>
          isString(subcategory) ? subcategory ?? null : subcategory?.id ?? null
        ) ?? null,
      descriptions: self?.descriptions?.map((description) =>
        Description.toFirebaseFormat(description)
      ),
      contact: Contact.toFirebaseFormat(self?.contact),
      extraInfo: {
        quantity: self?.extraInfo?.quantity ?? null,
        location: self?.extraInfo?.location ?? null,
        hourInit: self?.extraInfo?.hourInit ?? null,
        hourClose: self?.extraInfo?.hourClose ?? null,
        eventType: self?.extraInfo?.eventType ?? null,
        date: fromDateToTimestamp(self?.extraInfo?.date),
        dateLimit: fromDateToTimestamp(self?.extraInfo?.dateLimit),
      },
      id: self?.id ?? null,
      active: self?.active ?? null,
      createdAt: fromDateToTimestamp(self?.createdAt),
      updatedAt: fromDateToTimestamp(self?.updatedAt),
    };
  }

  static async createDigitalRequest(
    from: 'bloop' | 'bloopex'
  ): Promise<string> {
    const ref = await firebase
      .firestore()
      .collection(
        from === 'bloop' ? '/digitalRequestsBloop' : 'digitalRequests'
      )
      .add({
        categories: [],
      });
    await ref.update({ id: ref.id, categories: [] });
    return ref.id;
  }

  static async saveCategories(
    request: Partial<Request>,
    from: 'bloop' | 'bloopex'
  ): Promise<void> {
    firebase
      .firestore()
      .collection(
        from === 'bloop' ? '/digitalRequestsBloop' : 'digitalRequests'
      )
      .doc(`/${request?.id}`)
      .set(
        {
          ...(request ?? {}),
        },
        { merge: true }
      );
  }

  static async saveDescriptions(
    request: Partial<Request>,
    from: 'bloop' | 'bloopex'
  ): Promise<void> {
    firebase
      .firestore()
      .collection(
        from === 'bloop' ? '/digitalRequestsBloop' : 'digitalRequests'
      )
      .doc(`/${request?.id}`)
      .set(
        {
          descriptions:
            request?.descriptions?.map((description) =>
              Description.toFirebaseFormat(description)
            ) ?? null,
        },
        { merge: true }
      );
  }

  static async saveContact(state: any, id: string, from: 'bloop' | 'bloopex') {
    await firebase
      .firestore()
      .collection(
        from === 'bloop' ? '/digitalRequestsBloop' : 'digitalRequests'
      )
      .doc(`/${id}`)
      .set(
        {
          contact: {
            companyName: state?.companyName ?? '',
            firstName: state?.firstName ?? '',
            lastName: state?.lastName ?? '',
            email: state?.email ?? '',
            phone: state?.phone ?? '',
            eventType: state?.eventType ?? '',
            quantity: Number(state?.quantity ?? '0'),
            date: fromDateToTimestamp(fromStringToDate(state?.date ?? '')),
            location: state?.location ?? '',
            hours: [state?.hourInit ?? '', state?.hourClose ?? ''],
          },
        },
        { merge: true }
      );
  }
}
