import firebase from '../lib/firebase/client';
import { ContactPerson } from './ContactPerson';
import { isBoolean, isString, isTimestamp } from '../lib/guards';
import { FirebaseModel } from './FirebaseModel';
import { fromDateToTimestamp } from '../lib/dateHandler';
import { generateClientId } from '../lib/firebase/operations/generateId';
import { joinRif } from '../lib/formatRif';

export class Client extends FirebaseModel {
  code?: string;

  client?: string;

  type?: string;

  companyName?: string;

  dni?: string;

  dniType?: string;

  rif?: string;

  address?: string;

  web?: string;

  instagram?: string;

  contactPeople?: Array<ContactPerson>;

  phone?: string;

  email?: string;

  constructor();

  constructor(doc: any);

  constructor(doc?: any) {
    super(
      isString(doc?.id) ? doc?.id : undefined,
      isBoolean(doc?.active) ? doc?.active : true,
      isTimestamp(doc?.createdAt) ? doc?.createdAt?.toDate() : undefined,
      isTimestamp(doc?.updatedAt) ? doc?.updatedAt?.toDate() : undefined
    );
    if (typeof doc === 'undefined') {
      this.code = '';
      this.client = '';
      this.type = '';
      this.companyName = '';
      this.dni = '';
      this.dniType = '';
      this.rif = '';
      this.address = '';
      this.web = '';
      this.instagram = '';
      this.phone = '';
      this.email = '';
      this.contactPeople = [];
    }
    this.id = isString(doc?.id) ? doc?.id : undefined;
    this.active = isBoolean(doc?.active) ? doc?.active : true;
    this.createdAt = isTimestamp(doc?.createdAt)
      ? doc?.createdAt?.toDate()
      : new Date();
    this.updatedAt = isTimestamp(doc?.updatedAt)
      ? doc?.updatedAt?.toDate()
      : new Date();
    this.code = isString(doc?.code) ? doc?.code ?? null : null;
    this.client = isString(doc?.client) ? doc?.client ?? null : null;
    this.type = isString(doc?.type) ? doc?.type ?? null : null;
    this.companyName = isString(doc?.companyName)
      ? doc?.companyName ?? null
      : null;
    this.dni = isString(doc?.dni) ? doc?.dni ?? null : null;
    this.dniType = isString(doc?.dniType) ? doc?.dniType ?? null : null;
    this.rif = isString(doc?.rif) ? doc?.rif ?? null : null;
    this.address = isString(doc?.address) ? doc?.address ?? null : null;
    this.web = isString(doc?.web) ? doc?.web ?? null : null;
    this.instagram = isString(doc?.instagram) ? doc?.instagram ?? null : null;
    this.phone = isString(doc?.phone) ? doc?.phone ?? null : null;
    this.email = isString(doc?.email) ? doc?.email ?? null : null;
    this.contactPeople = Array.isArray(doc?.contactPeople)
      ? doc?.contactPeople?.map((person) => ContactPerson.factory(person))
      : [];
  }

  static factory(doc: any) {
    if (typeof doc === 'undefined' || doc === null) return null;
    return new Client(doc);
  }

  static toFirebaseFormat(self: Partial<Client>) {
    return {
      code: self?.code ?? null,
      client: self?.client ?? null,
      type: self?.type ?? null,
      companyName: self?.companyName ?? null,
      dni: self?.dni ?? null,
      dniType: self?.dniType ?? null,
      rif: self?.rif ?? null,
      address: self?.address ?? null,
      web: self?.web ?? null,
      instagram: self?.instagram ?? null,
      phone: self?.phone ?? null,
      email: self?.email ?? null,
      contactPeople: self?.contactPeople?.map((person) =>
        ContactPerson.toFirebaseFormat(person)
      ),
      id: self?.id ?? null,
      active: self?.active ?? null,
      createdAt: fromDateToTimestamp(self?.createdAt),
      updatedAt: fromDateToTimestamp(self?.updatedAt),
    };
  }

  static async create(state: any): Promise<string> {
    const id = await generateClientId();
    const _rif = joinRif(state?.rif, state?.dniType);
    await firebase
      .firestore()
      .collection('/clients')
      .doc(id)
      .set(
        {
          id,
          client: state?.client ?? '',
          type: state?.type !== 'none' ? state?.type ?? null : null,
          companyName: state?.companyName ?? null,
          rif: _rif,
          address: state?.address ?? null,
          web: state?.web ?? null,
          instagram: state?.instagram ?? null,
          contactPeople:
            state?.contactPeople?.map((person) =>
              ContactPerson.toFirebaseFormat(person)
            ) ?? [],
          phone: state?.phone ?? null,
          email: state?.contactPeople?.[0]?.email ?? '',
        },
        { merge: true }
      );
    return id;
  }

  static async update(state: any, id: string): Promise<string> {
    const _rif = joinRif(state?.rif, state?.dniType);
    await firebase
      .firestore()
      .collection('/clients')
      .doc(id)
      .set(
        {
          id,
          client: state?.client ?? '',
          type: state?.type !== 'none' ? state?.type ?? null : null,
          companyName: state?.companyName ?? null,
          rif: _rif,
          address: state?.address ?? null,
          web: state?.web ?? null,
          instagram: state?.instagram ?? null,
          contactPeople:
            state?.contactPeople?.map((person) =>
              ContactPerson.toFirebaseFormat(person)
            ) ?? [],
          phone: state?.phone ?? null,
          email: state?.contactPeople?.[0]?.email ?? '',
        },
        { merge: true }
      );
    return id;
  }

  static async delete(client: Partial<Client>): Promise<void> {
    await firebase
      .firestore()
      .collection('/clients')
      .doc(`/${client?.id}`)
      .delete();
  }

  static async deleteMany(ids: string[]): Promise<void> {
    const batch = firebase.firestore().batch();
    ids.forEach((id) => {
      batch.delete(firebase.firestore().collection('/clients').doc(`/${id}`));
    });
    await batch.commit();
  }
}
