import firebase from '../lib/firebase/client';
import { isBoolean, isNumber, isString, isTimestamp } from '../lib/guards';
import { Client } from './Client';
import { ContactPerson } from './ContactPerson';
import { FirebaseModel } from './FirebaseModel';
import { User } from './User';
import { Requirement } from './Requirement';
import { Observation } from './Observation';
import { generateProjectId } from '../lib/firebase/operations/generateId';
import { fromDateToTimestamp, fromStringToDate } from '../lib/dateHandler';

export class Project extends FirebaseModel {
  static phases = [
    { value: '1', text: '1. Registro del Cuestionario' },
    { value: '2', text: '2. Solicitud proveedores' },
    { value: '3', text: '3. Análisis del Proyecto' },
    { value: '4', text: '4. Creación de Cotización' },
    { value: '5', text: '5. Envío de la Cotización' },
    { value: '6', text: '6. Espera de Aprobación' },
    { value: '7', text: '7. Aprobado / Negociación' },
    { value: '8', text: '8. Análisis del proyecto' },
    { value: '9', text: '9. Registro de Proyecto' },
    { value: '10', text: '10. Producción del proyecto' },
    { value: '11', text: '11. Cierre / Facturación' },
    { value: '12', text: '12. Proyecto por cobrar' },
    { value: '13', text: '13. Proyecto finalizado' },
    { value: '14', text: '14. Cancelado' },
  ] as const;

  static PHASE_TOOLTIP = {
    '1': 'Registro del Cuestionario',
    '2': 'Solicitud proveedores',
    '3': 'Análisis del Proyecto',
    '4': 'Creación de Cotización',
    '5': 'Envío de la Cotización',
    '6': 'Espera de Aprobación',
    '7': 'Aprobado / Negociación',
    '8': 'Análisis del proyecto',
    '9': 'Registro de Proyecto',
    '10': 'Producción del proyecto',
    '11': 'Cierre / Facturación',
    '12': 'Proyecto por cobrar',
    '13': 'Proyecto finalizado',
    '14': 'Cancelado',
  } as const;

  static STATUS_TEXT = {
    '0': 'Tentativo',
    '1': 'Aprobado',
    '2': 'Ejecutado',
    '3': 'Finalizado',
    '4': 'Cancelado',
  } as const;

  client?: string | Client;

  project?: string;

  type?: string;

  eventType?: string;

  date?: Date;

  dateLimit?: Date;

  executive?: string | User;

  projectManagers?: Array<string | User>;

  phase?: number;

  status?: number;

  hours?: Array<string>;

  location?: string;

  quantity?: number;

  salesChannel?: string;

  attention?: string | ContactPerson;

  requirements?: Array<Requirement>;

  observations?: Observation;

  cancellation?: string;

  gaInt?: number;

  per?: number;

  fee?: number;

  approved?: boolean;

  contact?: string;

  platform?: string;

  constructor();

  constructor(doc: any);

  constructor(doc?: any) {
    super(
      isString(doc?.id) ? doc?.id : undefined,
      isBoolean(doc?.active) ? doc?.active : true,
      isTimestamp(doc?.createdAt) ? doc?.createdAt?.toDate() : undefined,
      isTimestamp(doc?.updatedAt) ? doc?.updatedAt?.toDate() : undefined
    );
    if (typeof doc === 'undefined') {
      this.project = null;
      this.type = null;
      this.eventType = null;
      this.quantity = null;
      this.salesChannel = null;
      this.location = null;
      this.cancellation = null;
      this.contact = null;
      this.platform = null;
      this.gaInt = 0;
      this.per = 0;
      this.fee = 0;
      this.date = null;
      this.dateLimit = null;
      this.client = null;
      this.attention = null;
      this.executive = null;
      this.phase = 1;
      this.status = 1;
      this.hours = null;
      this.projectManagers = null;
      this.requirements = [];
      this.observations = null;
      this.approved = false;
    }
    this.id = isString(doc?.id) ? doc?.id : undefined;
    this.active = isBoolean(doc?.active) ? doc?.active : true;
    this.createdAt = isTimestamp(doc?.createdAt)
      ? doc?.createdAt?.toDate()
      : new Date();
    this.updatedAt = isTimestamp(doc?.updatedAt)
      ? doc?.updatedAt?.toDate()
      : new Date();
    this.project = isString(doc?.project) ? doc?.project ?? null : null;
    this.type = isString(doc?.type) ? doc?.type ?? null : null;
    this.eventType = isString(doc?.eventType) ? doc?.eventType ?? null : null;
    this.quantity = isNumber(doc?.quantity) ? doc?.quantity ?? 0 : 0;
    this.salesChannel = isString(doc?.salesChannel)
      ? doc?.salesChannel ?? null
      : null;
    this.location = isString(doc?.location) ? doc?.location ?? null : null;
    this.cancellation = isString(doc?.cancellation)
      ? doc?.cancellation ?? null
      : null;
    this.contact = isString(doc?.contact) ? doc?.contact ?? null : null;
    this.platform = isString(doc?.platform) ? doc?.platform ?? null : null;
    this.gaInt = isNumber(doc?.gaInt) ? doc?.gaInt ?? 0 : 0;
    this.per = isNumber(doc?.per) ? doc?.per ?? 0 : 0;
    this.fee = isNumber(doc?.fee) ? doc?.fee ?? 0 : 0;
    this.date = isTimestamp(doc?.date) ? doc?.date?.toDate() : new Date();
    this.dateLimit = isTimestamp(doc?.dateLimit)
      ? doc?.dateLimit?.toDate()
      : new Date();
    this.client = isString(doc?.client)
      ? doc?.client
      : Client.factory(doc?.client);
    this.attention = isString(doc?.attention)
      ? doc?.attention ?? null
      : ContactPerson.factory(doc?.attention);
    this.executive = isString(doc?.executive)
      ? doc?.executive
      : User.factory(doc?.executive);
    this.phase = isNumber(doc?.phase) ? doc?.phase : null;
    this.status = isNumber(doc?.status) ? doc?.status : null;
    this.hours = Array.isArray(doc?.hours)
      ? doc?.hours?.map((hour) => (isString(hour) ? hour : '')) ?? []
      : [];
    this.projectManagers = Array.isArray(doc?.projectManagers)
      ? doc?.projectManagers?.map((pm) =>
          isString(pm) ? pm : User.factory(pm)
        ) ?? []
      : [];
    this.requirements = Array.isArray(doc?.requirements)
      ? doc?.requirements?.map((requirement) =>
          Requirement.factory(requirement)
        ) ?? []
      : [];
    this.observations = Observation.factory(doc?.observations);
    this.approved = isBoolean(doc?.approved) ? doc?.approved ?? false : false;
  }

  static factory(doc: any) {
    if (typeof doc === 'undefined' || doc === null) return null;
    return new Project(doc);
  }

  static toFirebaseFormat(self: Partial<Project>) {
    return {
      client: isString(self?.client)
        ? self?.client ?? null
        : self?.client?.id ?? null,
      project: self?.project ?? null,
      type: self?.type ?? null,
      eventType: self?.eventType ?? null,
      date: fromDateToTimestamp(self?.date),
      dateLimit: fromDateToTimestamp(self?.dateLimit),
      executive: isString(self?.executive)
        ? self?.executive ?? null
        : self?.executive?.id ?? null,
      projectManagers: self?.projectManagers?.map((pm) =>
        isString(pm) ? pm ?? null : pm?.id ?? null
      ),
      phase: self?.phase ?? null,
      status: self?.status ?? null,
      hours: self?.hours ?? null,
      location: self?.location ?? null,
      quantity: self?.quantity ?? null,
      salesChannel: self?.salesChannel ?? null,
      attention: isString(self?.attention)
        ? self?.attention ?? null
        : self?.attention?.id ?? null,
      requirements:
        self?.requirements?.map(
          (requirement) => Requirement?.toFirebaseFormat(requirement) ?? null
        ) ?? null,
      observations: Observation.toFirebaseFormat(self?.observations),
      cancellation: self?.cancellation ?? null,
      gaInt: self?.gaInt ?? null,
      per: self?.per ?? null,
      fee: self?.fee ?? null,
      approved: self?.approved ?? null,
      contact: self?.contact ?? null,
      platform: self?.platform ?? null,
      id: self?.id ?? null,
      active: self?.active ?? null,
      createdAt: fromDateToTimestamp(self?.createdAt),
      updatedAt: fromDateToTimestamp(self?.updatedAt),
    };
  }

  static async updateStatus(project: Project, update: Partial<Project>) {
    await firebase.firestore().collection('/projects').doc(project?.id).set(
      {
        phase: update?.phase,
        status: update?.status,
      },
      {
        merge: true,
      }
    );
  }

  static async createFromState(state: any): Promise<string> {
    const id = await generateProjectId();
    await firebase
      .firestore()
      .collection('/projects')
      .doc(`/${id}`)
      .set(
        {
          id,
          client: isString(state.client)
            ? state?.client ?? null
            : state?.client?.id ?? null,
          project: state?.project ?? '',
          type: state?.type === 'none' ? '' : state?.type ?? '',
          eventType: state?.eventType === 'none' ? '' : state?.eventType ?? '',
          date: fromDateToTimestamp(fromStringToDate(state?.date)),
          dateLimit: fromDateToTimestamp(fromStringToDate(state?.dateLimit)),
          executive:
            state?.executive !== 'none' ? state?.executive ?? null : null,
          projectManagers: state?.projectManagers ?? [],
          phase: Number(state?.phase || '1'),
          status: 1,
          hours: [state?.initHour ?? '', state?.closeHour ?? ''],
          location: state?.location ?? '',
          quantity: Number(state?.quantity ?? '0'),
          salesChannel:
            state?.salesChannel === 'none' ? '' : state?.salesChannel ?? '',
          attention: state?.attention ?? '',
          requirements: state?.requirements ?? [],
          observations: {
            general: [],
            suppliers: [],
            hours: [],
          },
          cancellation: '',
          gaInt: 0,
          per: 0,
          fee: 0,
          approved: false,
          contact: '',
          platform: 'Plataforma Bloopex',
          createdAt: fromDateToTimestamp(new Date()),
        },
        { merge: true }
      );
    return id;
  }

  static async updateFromState(state: any, id: string): Promise<void> {
    console.log(state);
    await firebase
      .firestore()
      .collection('/projects')
      .doc(`/${id}`)
      .set(
        {
          id,
          client: isString(state.client)
            ? state?.client ?? null
            : state?.client?.id ?? null,
          project: state?.project ?? '',
          type: state?.type === 'none' ? '' : state?.type ?? '',
          eventType: state?.eventType === 'none' ? '' : state?.eventType ?? '',
          date: fromDateToTimestamp(fromStringToDate(state?.date)),
          dateLimit: fromDateToTimestamp(fromStringToDate(state?.dateLimit)),
          executive: state?.executive ?? '',
          projectManagers: state?.projectManagers ?? [],
          phase: Number(state?.phase) ?? 1,
          status: Number(state?.status ?? '0'),
          hours: [state?.initHour ?? '', state?.closeHour ?? ''],
          location: state?.location ?? '',
          quantity: Number(state?.quantity ?? '0'),
          salesChannel:
            state?.salesChannel === 'none' ? '' : state?.salesChannel ?? '',
          attention: state?.attention ?? '',
          cancellation: state?.cancellation ?? '',
          contact: state?.contact ?? '',
        },
        { merge: true }
      );
  }

  static async update(project: Partial<Project>): Promise<void> {
    await firebase
      .firestore()
      .collection('/projects')
      .doc(`/${project?.id}`)
      .set(
        {
          ...(Project.toFirebaseFormat(project) ?? {}),
        },
        {
          merge: true,
        }
      );
  }

  static async deleteMany(ids: string[]): Promise<void> {
    const batch = firebase.firestore().batch();
    ids.forEach((id) => {
      batch.delete(firebase.firestore().collection('/projects').doc(`/${id}`));
    });
    await batch.commit();
  }
}
