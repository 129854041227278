import firebase from '../lib/firebase/client';
import { isBoolean, isString, isTimestamp } from '../lib/guards';
import { FirebaseModel } from './FirebaseModel';
import { User } from './User';
import { Project } from './Project';
import { fromDateToTimestamp } from '../lib/dateHandler';

export class Notification extends FirebaseModel {
  static NOTIFICATION_TYPE = {
    INITIAL: 0,
    '1': 1,
    '2': 2,
    '3': 3,
  } as const;

  user?: string | User;

  type?: number;

  message?: string;

  date?: Date;

  project?: string | Project;

  seen?: boolean;

  constructor();

  constructor(doc: any);

  constructor(doc?: any) {
    super(
      isString(doc?.id) ? doc?.id : undefined,
      isBoolean(doc?.active) ? doc?.active : true,
      isTimestamp(doc?.createdAt) ? doc?.createdAt?.toDate() : undefined,
      isTimestamp(doc?.updatedAt) ? doc?.updatedAt?.toDate() : undefined
    );
    if (typeof doc === 'undefined') {
      this.type = Notification.NOTIFICATION_TYPE.INITIAL;
      this.message = '';
      this.date = null;
      this.seen = false;
      this.user = null;
      this.project = null;
    }
    this.id = isString(doc?.id) ? doc?.id : undefined;
    this.active = isBoolean(doc?.active) ? doc?.active : true;
    this.createdAt = isTimestamp(doc?.createdAt)
      ? doc?.createdAt?.toDate()
      : new Date();
    this.updatedAt = isTimestamp(doc?.updatedAt)
      ? doc?.updatedAt?.toDate()
      : new Date();
    this.type = Number(
      doc?.type ?? String(Notification.NOTIFICATION_TYPE.INITIAL)
    );
    this.message = isString(doc?.message) ? doc?.message ?? null : null;
    this.date = isTimestamp(doc?.date) ? doc?.date?.toDate() : new Date();
    this.seen = isBoolean(doc?.seen) ? doc?.seen ?? false : false;
    this.user = isString(doc?.user) ? doc?.user ?? '' : User.factory(doc?.user);
    this.project = isString(doc?.project)
      ? doc?.project ?? ''
      : Project.factory(doc?.project);
  }

  static factory(doc: any) {
    if (typeof doc === 'undefined' || doc === null) return null;
    return new Notification(doc);
  }

  static toFirebaseFormat(self: Partial<Notification>) {
    return {
      type: self?.type ?? null,
      message: self?.message ?? null,
      seen: self?.seen ?? null,
      user: isString(self?.user) ? self?.user ?? null : self?.user?.id ?? null,
      project: isString(self?.project)
        ? self?.project ?? null
        : self?.project?.id ?? null,
      date: fromDateToTimestamp(self?.date),
      id: self?.id ?? null,
      active: self?.active ?? null,
      createdAt: fromDateToTimestamp(self?.createdAt),
      updatedAt: fromDateToTimestamp(self?.updatedAt),
    };
  }

  static async create(
    data: Record<string, any>,
    platform: 'Bloop' | 'Bloopex'
  ): Promise<void> {
    const ref = await firebase.firestore().collection('/notifications').add({});
    ref.set(
      {
        id: ref.id,
        seen: false,
        user: data.user,
        type: Notification.NOTIFICATION_TYPE.INITIAL,
        message: `Plataforma ${platform}: ${data?.message ?? ''}`,
        date: fromDateToTimestamp(new Date()),
        project: data.project,
      },
      { merge: true }
    );
  }
}
